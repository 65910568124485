import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";
import { Icons } from "@ster/ster-toolkit";
import { addDays, formatISO, parseJSON, startOfDay } from "date-fns";
import Cookies, { CookieAttributes } from "js-cookie";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { AnyAction } from "redux-saga";
import {
  EmptyActionCreator,
  PayloadAction,
  PayloadActionCreator,
  Reducer,
  createReducer,
} from "typesafe-actions";

import { MediumEnum } from "../api";
import {
  BreakSelection,
  CommercialInstructionStatus,
  CommercialStatus,
  InvoiceStatus,
  OperationalContextState,
  OrderStatus,
  PortalUser,
  SubOrderRequest,
  TenantEnum,
  VolumeDiscount,
} from "../api/models";
import { Loading, ReduxStoreState } from "../store/base";
import {
  advertiserCookieName,
  organisationCookieName,
  tenantCookieName,
} from "./constants";

export type { RangeValueType } from "rc-picker/lib/PickerInput/RangePicker";

export const defaultCookieOptions: Readonly<CookieAttributes> = Object.freeze({
  path: "/",
  expires: 365,
  sameSite: "Lax",
  secure: true,
});

export const getToken = (): string | undefined =>
  Cookies.get("sterlogin-access-token");

export const getLanguage = (): string =>
  Cookies.get("sterlogin-language") ?? "nl";

export const getLastRead = (): Date =>
  parseJSON(Cookies.get("ster-lastRead") ?? "1970-01-01T00:00:00.000Z");

export const setLastRead = () =>
  Cookies.set("ster-lastRead", formatISO(new Date()), defaultCookieOptions);

export const getAdvertiserFromCookie = (): number | undefined => {
  const advertiserCode = Cookies.get(advertiserCookieName);
  if (!advertiserCode) {
    return undefined;
  }

  return parseInt(advertiserCode, 10);
};
export const getOrganisationFromCookie = (): string | undefined =>
  Cookies.get(organisationCookieName);

export const getOrganisationCode = (account: PortalUser): string =>
  getOrganisationFromCookie() ?? account.userInfo?.organisationCode ?? "";

export const getTenantFromCookie = (): TenantEnum | undefined => {
  const tenantValue = Cookies.get(tenantCookieName);
  if (Object.values(TenantEnum).includes(tenantValue as TenantEnum)) {
    return tenantValue as TenantEnum;
  }
  return undefined;
};

export const setTenantInCookie = (tenant: TenantEnum | undefined) => {
  if (tenant === undefined) {
    Cookies.remove(tenantCookieName);
  } else {
    Cookies.set(tenantCookieName, tenant, defaultCookieOptions);
  }
};

export const saveOperationalContextStateInCookie = (
  state: OperationalContextState | null
): void => {
  removeOperationalContextStateFromCookies();
  if (!state) {
    return;
  }

  Cookies.set(tenantCookieName, state.tenant, defaultCookieOptions);
  Cookies.set(
    organisationCookieName,
    state.organisationCode as string,
    defaultCookieOptions
  );

  if (state.advertiserCode) {
    Cookies.set(
      advertiserCookieName,
      state.advertiserCode.toString(),
      defaultCookieOptions
    );
  }
};

export const removeOperationalContextStateFromCookies = () => {
  Cookies.remove(tenantCookieName);
  Cookies.remove(advertiserCookieName);
  Cookies.remove(organisationCookieName);
};

/**
 * A custom hook that builds on useLocation to parse the query string for you.
 */
export const useQuery = (): URLSearchParams =>
  new URLSearchParams(useLocation().search);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): Record<K, T[]> =>
  list.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) {
        // eslint-disable-next-line no-param-reassign
        previous[group] = [];
      }
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>
  );

export const getMediumName = (
  medium: MediumEnum | undefined
): MessageDescriptor => {
  switch (medium) {
    case MediumEnum.Tv:
      return msg`Televisie`;
    case MediumEnum.Radio:
      return msg`Radio`;
    default:
      return msg`Online`;
  }
};

export const getMediumIcon = (
  medium: MediumEnum | undefined
): React.ReactElement => {
  switch (medium) {
    case MediumEnum.Tv:
      return <Icons.TVIcon />;
    case MediumEnum.Radio:
      return <Icons.RadioIcon />;
    default:
      return <Icons.OnlineIcon />;
  }
};

export const omit = (
  obj: Record<string, unknown>,
  fields: string[]
): Record<string, unknown> => {
  // eslint-disable-next-line prefer-object-spread
  const shallowCopy = Object.assign({}, obj);
  for (let i = 0; i < fields.length; i += 1) {
    const key = fields[i];
    delete shallowCopy[key];
  }
  return shallowCopy;
};

// Controleer of de property met de gegeven naam bestaat in het object
export const hasKey = <O extends object>(
  obj: O,
  key: keyof never
): key is keyof O => key in obj;

// eslint-disable-next-line no-shadow
export enum TagStatus {
  Info = "info",
  Warning = "warning",
  Success = "success",
  Default = "default",
}

export const tagStatusMapper = (
  status:
    | OrderStatus
    | InvoiceStatus
    | CommercialStatus
    | CommercialInstructionStatus
): TagStatus | undefined => {
  if (
    status === OrderStatus.Concept ||
    status === OrderStatus.Submitted ||
    status === InvoiceStatus.Gefactureerd ||
    status === CommercialInstructionStatus.InBehandeling
  ) {
    return TagStatus.Info;
  }
  if (
    status === OrderStatus.Planned ||
    status === InvoiceStatus.DeelsBetaald ||
    status === CommercialStatus.Inactive ||
    status === CommercialStatus.Nieuw ||
    status === CommercialInstructionStatus.Incorrect
  ) {
    return TagStatus.Warning;
  }
  if (
    status === OrderStatus.Active ||
    status === InvoiceStatus.Betaald ||
    status === CommercialStatus.Active ||
    status === CommercialInstructionStatus.Behandeld
  ) {
    return TagStatus.Success;
  }
  if (
    status === OrderStatus.Ready ||
    status === CommercialStatus.Offline ||
    status === CommercialInstructionStatus.Concept
  ) {
    return TagStatus.Default;
  }
  return undefined;
};

export const formatPercentage = (percentage: number): string =>
  Intl.NumberFormat("nl-NL", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(percentage);

// Mapping voor vertalingen
export const getOrderStatusTranslation = (
  status: OrderStatus
): MessageDescriptor => {
  switch (status) {
    case OrderStatus.Active:
      return msg`Actief`;
    case OrderStatus.Submitted:
      return msg`Ingediend`;
    case OrderStatus.Concept:
      return msg`Concept`;
    case OrderStatus.Planned:
      return msg`Gepland`;
    case OrderStatus.Ready:
      return msg`Afgerond`;
    default:
      return msg`Onbekend`;
  }
};

// Bepaal de volgorde van de statussen
export const getOrderStatusOrder = (status: OrderStatus): number =>
  Object.keys(OrderStatus).indexOf(status);

export const escapeRegex = (value: string): string =>
  value.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

export const logout = (): void => {
  Cookies.remove("ster_auth");
  Cookies.remove(organisationCookieName);
  Cookies.remove(advertiserCookieName);
  Cookies.remove("access_token");
  Cookies.remove("sterlogin-access-token", {
    path: "/",
    domain: window.location.hostname === "localhost" ? "localhost" : "ster.nl",
  });
};

// Mapping voor vertalingen
export const getVolumeDiscountTranslation = (
  discount: VolumeDiscount
): MessageDescriptor => {
  switch (discount) {
    case VolumeDiscount.VolumeDiscount0:
      return msg`Geen contract – 0% korting`;
    case VolumeDiscount.VolumeDiscount2:
      return msg`€ 250.000 tot € 500.000 – 2% korting`;
    case VolumeDiscount.VolumeDiscount3:
      return msg`€ 500.000 tot €1.000.000 – 3% korting`;
    case VolumeDiscount.VolumeDiscount4:
      return msg`€ 1.000.000 tot € 1.500.000 – 4% korting`;
    case VolumeDiscount.VolumeDiscount5:
      return msg`€ 1.500.000 en hoger – 5% korting`;
    default:
      return msg`Onbekend`;
  }
};

export const getInstructionStatusTranslation = (
  status: CommercialInstructionStatus
): MessageDescriptor => {
  switch (status) {
    case CommercialInstructionStatus.Behandeld:
      return msg`Behandeld`;
    case CommercialInstructionStatus.Concept:
      return msg`Concept`;
    case CommercialInstructionStatus.InBehandeling:
      return msg`In behandeling`;
    case CommercialInstructionStatus.Incorrect:
      return msg`Incorrect`;
    default:
      return msg`Onbekend`;
  }
};

// Bereken de prijs van een spotje van een BreakSelection op basis van de toeslagen voor een eventuele selectiviteit en voorkeurspositie
export const getSpotPrice = (
  breakSelection: BreakSelection,
  preferredPositionSurcharge: number
): number => {
  const nettSpotPrice = breakSelection._break?.nettSpotPrice ?? 0;
  const selectivitySurcharge = breakSelection._break?.selectivitySurcharge ?? 0;
  const preferredPositionCost =
    !breakSelection.preferredPosition ||
    breakSelection.preferredPosition === "NNNN"
      ? 0
      : (breakSelection._break?.grossSpotPrice ?? 0) *
        preferredPositionSurcharge;

  return Math.round(
    nettSpotPrice + selectivitySurcharge + preferredPositionCost
  );
};

// Generieke reducer die een Request, Success en Error functie afhandelt voor store items die van het `Loading` type overerven
// De dataKey is optioneel om de ontvangen data in de store onder een specifieke key weg te schrijven
export const genericReducer = <
  TState extends Loading,
  RequestAction = PayloadAction<never, never>,
  SuccessAction = PayloadActionCreator<never, never>,
  ErrorAction = PayloadActionCreator<never, never>,
  ClearAction = EmptyActionCreator<never>,
>(
  requestAction: RequestAction,
  successAction: SuccessAction,
  errorAction: ErrorAction,
  dataKey?: string,
  clearAction?: ClearAction
): Reducer<TState, AnyAction> => {
  const reducer = createReducer({
    state: ReduxStoreState.Initial,
    loading: false,
  })
    .handleAction(requestAction, (state: TState) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .handleAction(errorAction, (state: TState, action: ReturnType<any>) => ({
      error: action.payload,
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(
      successAction,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state: TState, action: ReturnType<any> | undefined) =>
        dataKey
          ? {
              [dataKey]: action?.payload,
              loading: false,
              state: ReduxStoreState.Success,
            }
          : {
              ...action?.payload,
              loading: false,
              state: ReduxStoreState.Success,
            }
    );

  if (clearAction) {
    return reducer.handleAction(clearAction, () =>
      dataKey
        ? {
            [dataKey]: undefined,
            loading: false,
            state: ReduxStoreState.Initial,
          }
        : {
            loading: false,
            state: ReduxStoreState.Initial,
          }
    );
  }

  return reducer;
};

// Geef alleen de unieke items van een array terug
export const UniqueStrings = (array: string[]): string[] =>
  Array.from(new Set(array));

// Geef alleen de unieke items van een array terug
export const UniqueNumbers = (array: number[]): number[] =>
  Array.from(new Set(array));

//  Filter dubbele items in array
export const removeDuplicateObjects = (list: unknown[]): unknown[] => {
  const uniq = new Set(list.map((e) => JSON.stringify(e)));
  return Array.from(uniq).map((e) => JSON.parse(e));
};

//  Filter dubbele datums
export const removeAndSortDuplicateDates = (list: Date[]): Date[] =>
  removeDuplicateObjects(list)
    .map((s) => new Date(s as string))
    .sort((a, b) => Number(new Date(a)) - Number(new Date(b)));

export const isAccessTokenValid = (
  token: string | undefined = undefined
): boolean => {
  const accessToken = token ?? getToken();
  if (!accessToken) {
    return false;
  }
  const { exp, Application } = jwtDecode<{
    exp: number;
    Application: string[];
  }>(accessToken);
  if (!Application?.includes("klantportal")) {
    return false;
  }

  const now = moment();
  const expires = moment.unix(exp);
  return !now.isSameOrAfter(expires);
};

export const getBlockPositionText = (
  selectedPreferredPosition: string
): MessageDescriptor[] => {
  const positions = [];
  for (let i = 0; i < selectedPreferredPosition.length; i += 1) {
    if (selectedPreferredPosition[i] === "J") {
      // eslint-disable-next-line default-case
      switch (i) {
        case 0:
          positions.push(msg`1e in blok`);
          break;
        case 1:
          positions.push(msg`2e in blok`);
          break;
        case 2:
          positions.push(msg`Op 1 na laatste in blok`);
          break;
        case 3:
          positions.push(msg`Laatste in blok`);
          break;
      }
    }
  }

  if (positions.length > 0) {
    return positions;
  }

  return [msg`Geen voorkeur`];
};

// Bepaalt de container ten opzichte waarvan popups binnen de deelorders gepositioneerd moeten worden
export const getPopupContainerSubOrders = (
  triggernode: HTMLElement
): HTMLElement =>
  document.getElementById("suborders") ??
  triggernode.parentElement ??
  triggernode;

export const subOrderGenericFieldsFilled = (
  subOrder: Partial<SubOrderRequest>
): boolean =>
  subOrder.period !== undefined &&
  (subOrder.spotLength?.filter((s) => s).length ?? 0) > 0 &&
  subOrder._package?.code !== undefined &&
  subOrder.targetGroup?.targetGroupId !== undefined;

export const range = (start: number, end: number) =>
  Array.from(Array(end - start + 1).keys()).map((x) => x + start);

export const getCommercialStatusText = (status: CommercialStatus) => {
  switch (status) {
    case CommercialStatus.Active:
      return msg`Actief`;
    case CommercialStatus.Inactive:
      return msg`Inactief`;
    case CommercialStatus.Offline:
      return msg`Offline`;
    case CommercialStatus.Nieuw:
      return msg`Nieuw`;
    default:
      return msg`Onbekend`;
  }
};

export const emptyNumbers: number[] = [];

// Geeft terug of boekspot verzoeken direct verwerkt worden: ma-vrij 09:10-17:55
export const insideImmediateBookSpotWindow = () => {
  const startDay = 0;
  const endDay = 5;
  const beginningTime = moment({
    h: 9,
    m: 10,
  });
  const endTime = moment({
    h: 17,
    m: 55,
  });

  return (
    moment().day() >= startDay &&
    moment().day() <= endDay &&
    moment().isBetween(beginningTime, endTime)
  );
};

export interface InstructionConstantsInterface {
  now: Date;
  firstMoment: Date;
  firstMomentOnline: Date;
  emptyTicks: number[];
}

export const InstructionConstants: InstructionConstantsInterface =
  Object.freeze({
    now: new Date(),
    firstMoment: addDays(startOfDay(new Date()), 1),
    firstMomentOnline: new Date(),
    emptyTicks: [],
  });

export const Sum = (values?: number[] | null) =>
  values?.reduce((a, b) => a + (b ?? 0)) ?? 0;

export const isNumber = <T,>(value: T): boolean => !Number.isNaN(Number(value));

export const isArray = <T,>(x: T | T[]): x is T[] =>
  (x as T[]).length !== undefined;
