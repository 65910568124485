import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Spinner, formatToEuro } from "@ster/ster-toolkit";
import { Alert, Form as AntForm } from "antd";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { Store } from "antd/lib/form/interface";
import { endOfDay, startOfDay } from "date-fns";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PackageChoiceEnum, SubOrderRequest, UpdateFields } from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import {
  receiveContextTargetsAction,
  receiveProgramsAction,
  receiveSitesAction,
  receiveSpotIndexesAction,
  receiveTargetGroupsAction,
} from "../../../store/campaignCreate/actions";
import { StoreModel } from "../../../store/models";
import { hasKey } from "../../../utils";
import {
  actionOrientedValue,
  brandAwerenessValue,
} from "../../../utils/constants";
import { SubOrderOnlineEditProps } from "./models";
import OnlineSubOrderEditForm from "./OnlineSubOrderEditForm";
import OnlineSubOrderForm from "./OnlineSubOrderForm";
import GrpBudget from "./partials/GrpBudget";
import PackageOptionsOnline from "./partials/PackageOptionsOnline";
import { onlineSubOrderSelector } from "./selectors";

const deviceTargetsDisplay: CheckboxOptionType[] = [
  { label: "Desktop/laptop", value: "desktop" },
  { label: "Tablet", value: "tablet" },
  { label: "Mobiel", value: "mobiel" },
];

const deviceTargetsVideo: CheckboxOptionType[] = [
  ...deviceTargetsDisplay,
  { label: "Connected TV", value: "connected_tv" },
];

/**
 * Toevoegen/wijzigen van een online deelorder.
 */
const OnlineSubOrder = memo(
  ({
    onChange,
    subOrder,
    subOrderOriginal,
    onFinishFailed,
    period,
    medium,
    productId,
    packageChoice,
    isEditCampaign,
    selectedSalesPeriod,
    requestDate,
  }: SubOrderOnlineEditProps) => {
    const { i18n } = useLingui();

    const [form] = AntForm.useForm();
    const dispatch = useDispatch();

    const {
      displayPackages,
      videoPackages,
      loading,
      sites,
      programs,
      contextTargets,
      spotIndexes,
      states: {
        sites: sitesState,
        contextTargets: contextTargetsState,
        programs: programsState,
        targetGroups: targetGroupsState,
        spotIndexes: spotIndexesState,
      },
    } = useSelector((state: StoreModel) =>
      onlineSubOrderSelector(state, { period, medium })
    );

    const packages = useMemo(
      () =>
        packageChoice === PackageChoiceEnum.Display
          ? displayPackages
          : videoPackages,
      [displayPackages, packageChoice, videoPackages]
    );

    const selectedPackage = useMemo(
      () => packages?.find((p) => p.code === subOrder._package?.code),
      [packages, subOrder]
    );

    const deviceTargets = useMemo(
      () =>
        packageChoice === PackageChoiceEnum.Display
          ? deviceTargetsDisplay
          : deviceTargetsVideo.map((s) => ({
              ...s,
              disabled:
                s.value === "connected_tv" &&
                subOrder.revenueType === actionOrientedValue, // Bij een actiematige campage sluiten we connected tv uit
            })),
      [packageChoice, subOrder.revenueType]
    );

    // Haal sites op
    useEffect(() => {
      if (packageChoice === PackageChoiceEnum.Video) {
        if (programsState === ReduxStoreState.Initial) {
          dispatch(receiveProgramsAction.request());
        }
        if (spotIndexesState === ReduxStoreState.Initial) {
          dispatch(
            receiveSpotIndexesAction.request({
              medium,
              year: period.from.getFullYear(),
            })
          );
        }
      }

      if (packageChoice === PackageChoiceEnum.Display) {
        if (sitesState === ReduxStoreState.Initial) {
          dispatch(receiveSitesAction.request());
        }
      }

      if (targetGroupsState === ReduxStoreState.Initial) {
        dispatch(receiveTargetGroupsAction.request());
      }
      if (contextTargetsState === ReduxStoreState.Initial) {
        dispatch(receiveContextTargetsAction.request());
      }
    }, [
      contextTargetsState,
      dispatch,
      medium,
      packageChoice,
      period.from,
      programsState,
      sitesState,
      spotIndexesState,
      targetGroupsState,
    ]);

    useEffect((): void => {
      if (subOrder) {
        // Vul het formulier met de bestaande deelorder
        form.setFieldsValue({
          videoLength: subOrder.spotLength?.[0],
          ...subOrder,
          period: subOrder.period
            ? [subOrder.period.from, subOrder.period.to]
            : undefined,
          packageCode: subOrder._package?.code,
          targeting:
            form.getFieldValue("targeting") ??
            // eslint-disable-next-line no-nested-ternary
            (subOrder.program
              ? "program"
              : // eslint-disable-next-line no-nested-ternary
                subOrder.site
                ? "site"
                : subOrder.contextTargets
                  ? "context"
                  : null),
        });
      }
    }, [subOrder, form]);

    // Wijzigen van het formulier
    const handleValuesChange = useCallback(
      (changedValues: Store) => {
        const resetFields = [];
        const defaultValues: Partial<SubOrderRequest> = {};
        const newSuborder: Partial<SubOrderRequest> = {
          // default bij video = awareness
          revenueType:
            packageChoice === PackageChoiceEnum.Video ? 4 : undefined,
          ...subOrder,
          ...changedValues,
        };
        let currentPackage = selectedPackage;

        if (
          changedValues.revenueType &&
          changedValues.revenueType === actionOrientedValue
        ) {
          // Bij een actiematige campagne sluiten we connected tv uit
          defaultValues.deviceTargets = newSuborder.deviceTargets?.filter(
            (s) => s !== "connected_tv"
          );
        }

        if (
          changedValues.revenueType &&
          changedValues.revenueType === brandAwerenessValue
        ) {
          // Bij het wisselen naar naamsbekendheid zetten we 'connected tv' aan
          defaultValues.deviceTargets = [
            ...(newSuborder.deviceTargets ?? []),
            "connected_tv",
          ];
        }

        if (changedValues.period) {
          defaultValues.period = {
            from: changedValues.period[0],
            to: changedValues.period[1],
          };
        }

        if (
          changedValues.useTime !== undefined &&
          subOrder.period?.from &&
          subOrder.period?.to
        ) {
          defaultValues.period = {
            from: changedValues.useTime
              ? startOfDay(subOrder.period.from) // tijd: 00:00
              : subOrder.period.from,
            to: changedValues.useTime
              ? endOfDay(subOrder.period.to) // tijd: 23:59
              : subOrder.period.to,
          };
        }

        if (changedValues.videoLength) {
          defaultValues.spotLength = [changedValues.videoLength];
        }

        if (changedValues.packageCode) {
          const newPackage = packages?.find(
            (p) => p.code === changedValues.packageCode
          );
          defaultValues._package = newPackage;
          currentPackage = newPackage;

          resetFields.push(
            "site",
            "program",
            "timeSelection",
            "contextTargets",
            "deviceTargets",
            "targetGroups"
          );
        }

        if (changedValues.targeting) {
          resetFields.push("program", "site", "contextTargets");
        }

        // Vul de device targets standaard met alle devices, je kan nooit 0 devices kiezen
        if ((newSuborder.deviceTargets?.length ?? 0) === 0) {
          defaultValues.deviceTargets = deviceTargets
            .filter((s) => !s.disabled)
            .map((d) => d.value.toString());
        }

        if (packageChoice === PackageChoiceEnum.Video) {
          // Zoek het format op basis van de spotlengte
          defaultValues.format =
            currentPackage?.internetFormat?.find(
              (s) =>
                s.spotLength ===
                (defaultValues.spotLength || subOrder.spotLength)?.[0]
            )?.id ?? "";
        } else if (currentPackage?.internetFormat?.length === 1) {
          defaultValues.format = currentPackage.internetFormat[0].id;
        }

        defaultValues.discountProperties = {
          timeTargeting: (newSuborder.timeSelection?.length ?? 0) > 0,
        };

        // Voer een herberekening uit
        if (
          subOrder.budget &&
          [
            "packageCode",
            "timeSelection",
            "period",
            "videoLength",
            "format",
          ].filter((s) => hasKey({ ...changedValues, ...defaultValues }, s))
            .length > 0
        ) {
          // Vul het veld calculateTimeStamp met de huidige timestamp om een herberekening te forceren
          form.setFieldsValue({ calculateTimeStamp: +new Date() });
        }

        form.resetFields(resetFields);
        onChange({
          ...newSuborder,
          ...defaultValues,
          ...Object.assign(
            // Alle velden die gereset moeten worden krijgen de waarde undefined
            {},
            ...resetFields.map((s) => ({ [s]: undefined }))
          ),
        });
      },
      [
        deviceTargets,
        form,
        onChange,
        packageChoice,
        packages,
        selectedPackage,
        subOrder,
      ]
    );

    // Afhandelen van het optreden van een error bij het versturen van het formulier
    const handleFinishFailed = useCallback(() => {
      onFinishFailed(subOrder.id ?? 0);
    }, [onFinishFailed, subOrder]);

    const handleSelectAll = useCallback(
      (selectAll: boolean) => {
        form.setFieldsValue({
          site: selectAll ? sites.map((s) => s.name) : [],
        });
      },
      [form, sites]
    );

    const budgetValidation = useMemo(
      () =>
        isEditCampaign &&
        subOrder.budget &&
        subOrderOriginal?.budget &&
        subOrder.budget < subOrderOriginal.budget ? (
          <Form.Item
            name="increaseOnlyWarning"
            rules={[
              {
                validator: (): Promise<void> => Promise.reject(),
              },
            ]}
          >
            <Alert
              showIcon
              type="error"
              message=""
              description={i18n._(
                t`Je mag het budget van ${formatToEuro(
                  subOrderOriginal.budget ?? 0,
                  false
                )} alleen verhogen.`
              )}
            />
          </Form.Item>
        ) : undefined,
      [i18n, isEditCampaign, subOrder.budget, subOrderOriginal?.budget]
    );

    const canUpdateField = useCallback(
      (updateField: UpdateFields) =>
        !isEditCampaign ||
        (subOrder.editableFields?.includes(updateField) ?? false),
      [isEditCampaign, subOrder.editableFields]
    );

    return (
      <Spinner spinning={loading}>
        {isEditCampaign ? (
          <OnlineSubOrderEditForm
            handleFinishFailed={handleFinishFailed}
            handleValuesChange={handleValuesChange}
            form={form}
            subOrder={subOrder}
            selectedPackage={selectedPackage}
            packageChoice={packageChoice}
            selectedSalesPeriod={selectedSalesPeriod}
          />
        ) : (
          <OnlineSubOrderForm
            handleFinishFailed={handleFinishFailed}
            handleSelectAll={handleSelectAll}
            handleValuesChange={handleValuesChange}
            contextTargets={contextTargets}
            form={form}
            packageChoice={packageChoice}
            packages={packages}
            period={period}
            programs={programs}
            sites={sites}
            subOrder={subOrder}
            selectedPackage={selectedPackage}
          />
        )}

        {selectedPackage && !isEditCampaign && (
          <PackageOptionsOnline
            onChange={handleValuesChange}
            subOrder={subOrder}
            selectedPackage={selectedPackage}
            onFinishFailed={handleFinishFailed}
            deviceTargets={deviceTargets}
            form={form}
            packageChoice={packageChoice}
          />
        )}

        {subOrder.format && (
          <GrpBudget
            subOrder={subOrder}
            medium={medium}
            packages={packages}
            onChange={onChange}
            productId={productId}
            spotIndexes={spotIndexes}
            onFinishFailed={handleFinishFailed}
            calculateTimeStamp={form.getFieldValue("calculateTimeStamp")}
            appendFormItem={budgetValidation}
            requestDate={requestDate}
            disabled={!canUpdateField(UpdateFields.Budget)}
          />
        )}
      </Spinner>
    );
  }
);

export default OnlineSubOrder;
