import { parse } from "date-fns";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import {
  ApiV1CustomerContactsAccountIdGetRequest,
  ApiV1CustomerContactsAdvertiserIdGetRequest,
  ApiV1CustomerOpportunitiesAccountIdGetRequest,
  ApiV1CustomerOpportunitiesAdvertiserIdGetRequest,
  ApiV1RequestCalculatePostRequest,
  CampaignAnalysisApi,
  CampaignApi,
  CampaignImportOutput,
  CustomerApi,
  ForecastApi,
  GenericApi,
  MediumEnum,
  OrderRequest,
  PlanningApi,
  ProblemDetails,
  ProposalApi,
  RatecardApi,
  RequestApi,
} from "../../api";
import apiConfig from "../../apiConfig";
import { router } from "../../components/AppContainer";
import { getMinMax } from "../../components/campaignCreate/instructions/InstructionsForConcept";
import { getFormattedDate } from "../../utils/dateHelper";
import { receiveCampaignInstructionsAction } from "../campaignDetail/actions";
import { toError } from "../utils";
import {
  analysisUploadFileAction,
  campaignConfigurationFetchAction,
  finalizeRequestAction,
  importConfigurationAction,
  proposalGenerateAction,
  proposalUploadForecastAction,
  receiveBookingDateAction,
  receiveCalculationAction,
  receiveContextTargetsAction,
  receiveCustomerAccountsAction,
  receiveCustomerContactsAction,
  receiveCustomerOpportunitiesAction,
  receiveForecastAction,
  receiveForecastConversionGroupsAction,
  receiveGroupedPackagesRadioAction,
  receiveGroupedPackagesTvAction,
  receiveInitialRequestAction,
  receivePackagesAction,
  receiveProductsAction,
  receiveProgramsAction,
  receiveSalesPeriodsAction,
  receiveSitesAction,
  receiveSpotIndexesAction,
  receiveTargetGroupsAction,
  saveRequestAction,
} from "./actions";
import { BookingDateMapped } from "./models";

export function* receiveSalesPeriods(): SagaIterator {
  try {
    const genericApi = new GenericApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1GenericGetsalesperiodGet()
    );
    yield put(receiveSalesPeriodsAction.success(response));
  } catch (err) {
    yield put(receiveSalesPeriodsAction.failure(toError(err)));
  }
}

export function* receiveProducts(
  action: ReturnType<typeof receiveProductsAction.request>
): SagaIterator {
  try {
    const genericApi = new GenericApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1GenericGetproductsGet(action.payload)
    );
    yield put(receiveProductsAction.success(response));
  } catch (err) {
    yield put(receiveProductsAction.failure(toError(err)));
  }
}

export function* receiveInitialRequest(
  action: ReturnType<typeof receiveInitialRequestAction.request>
): SagaIterator {
  try {
    const planningApi = new PlanningApi(apiConfig());
    const response = yield call(() =>
      planningApi.apiV1PlanningInitialRequestIdGet(action.payload)
    );
    yield put(receiveInitialRequestAction.success(response));
  } catch (err) {
    yield put(
      receiveInitialRequestAction.failure({
        initialRequestId: action.payload.initialRequestId,
        from: action.payload.from,
        ...toError(err),
      })
    );
  }
}

export function* receiveSpotIndexes(
  action: ReturnType<typeof receiveSpotIndexesAction.request>
): SagaIterator {
  try {
    const genericApi = new RatecardApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1RatecardSpotindexesGet(action.payload)
    );
    yield put(
      receiveSpotIndexesAction.success({
        medium: action.payload.medium,
        ...response,
      })
    );
  } catch (err) {
    yield put(
      receiveSpotIndexesAction.failure({
        medium: action.payload.medium,
        ...toError(err),
      })
    );
  }
}

export function* receivePackages(
  action: ReturnType<typeof receivePackagesAction.request>
): SagaIterator {
  try {
    const genericApi = new RequestApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1RequestGetpackagesGet(action.payload)
    );
    yield put(receivePackagesAction.success(response));
  } catch (err) {
    yield put(receivePackagesAction.failure(toError(err)));
  }
}

export function* receiveGroupedPackagesRadio(
  action: ReturnType<typeof receiveGroupedPackagesRadioAction.request>
): SagaIterator {
  try {
    const genericApi = new RequestApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1RequestGetpackagesGroupedRadioYearGet(action.payload)
    );
    yield put(
      receiveGroupedPackagesRadioAction.success({
        [action.payload.year]: response,
      })
    );
  } catch (err) {
    yield put(receiveGroupedPackagesRadioAction.failure(toError(err)));
  }
}

export function* receiveGroupedPackagesTv(
  action: ReturnType<typeof receiveGroupedPackagesTvAction.request>
): SagaIterator {
  try {
    const genericApi = new RequestApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1RequestGetpackagesGroupedTvYearGet(action.payload)
    );
    yield put(
      receiveGroupedPackagesTvAction.success({
        [action.payload.year]: response,
      })
    );
  } catch (err) {
    yield put(receiveGroupedPackagesTvAction.failure(toError(err)));
  }
}

export function* receiveBookingDate(
  action: ReturnType<typeof receiveBookingDateAction.request>
): SagaIterator {
  try {
    const planningApi = new PlanningApi(apiConfig());
    const response = yield call(() =>
      planningApi.apiV1PlanningBookingdateGet(action.payload)
    );

    // Dates from the API are in the format yyyy-MM-dd (C# DateOnly),
    // parse them to Date objects manually
    yield put(
      receiveBookingDateAction.success({
        ...response,
        date: parse(response.date, "yyyy-MM-dd", new Date()),
        spotsEditableDate: parse(
          response.spotsEditableDate,
          "yyyy-MM-dd",
          new Date()
        ),
      } as BookingDateMapped)
    );
  } catch (err) {
    yield put(receiveBookingDateAction.failure(toError(err)));
  }
}

export const redirectRequest = ({
  id,
  medium,
  period,
}: Partial<OrderRequest>) => {
  if (!period) {
    return;
  }

  router.navigate(
    `/campaigns/new/${medium}/${id}/${getFormattedDate(
      period.from
    )}/${getFormattedDate(period.to)}`
  );
};

export function* saveRequest(
  action: ReturnType<typeof saveRequestAction.request>
): SagaIterator {
  try {
    const planningApi = new PlanningApi(apiConfig());
    const response: OrderRequest = yield call(() =>
      planningApi.apiV1PlanningSavePost({
        orderRequest: action.payload.orderRequest,
      })
    );
    yield put(
      saveRequestAction.success({
        initialRequestId: response.id ?? 0,
        order: response,
      })
    );

    if (action.payload.onSuccess) {
      const {
        medium,
        period: { from: periodFrom, to: periodTo },
        id: orderId,
      } = response;
      yield call(action.payload.onSuccess, {
        medium,
        orderId,
        periodFrom,
        periodTo,
      });
    }

    yield call(redirectRequest, response);
  } catch (err) {
    yield put(
      finalizeRequestAction.failure({
        initialRequestId: action.payload.orderRequest.id ?? 0,
        from: action.payload.orderRequest.period.from,
        ...toError(err),
      })
    );
  }
}

export function* finalizeRequest(
  action: ReturnType<typeof finalizeRequestAction.request>
): SagaIterator {
  try {
    const planningApi = new PlanningApi(apiConfig());
    const response: OrderRequest = yield call(() =>
      planningApi.apiV1PlanningFinalizePost({
        orderRequest: action.payload.orderRequest,
      })
    );
    yield put(
      finalizeRequestAction.success({
        initialRequestId: response.id ?? 0,
        order: response,
      })
    );

    if (action.payload.orderRequest.medium !== MediumEnum.Inter) {
      const { medium, productId } = response;
      const { min: from, max: to } = getMinMax(response);
      yield put(
        receiveCampaignInstructionsAction.request({
          medium,
          productId,
          from,
          to,
        })
      );
    }

    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess, response);
    }

    yield call(redirectRequest, response);
  } catch (err) {
    yield put(
      saveRequestAction.failure({
        initialRequestId: action.payload.orderRequest.id ?? 0,
        from: action.payload.orderRequest.period.from,
        ...toError(err),
      })
    );
  }
}

export function* receiveForecast(
  action: ReturnType<typeof receiveForecastAction.request>
): SagaIterator {
  try {
    const forecastApi = new ForecastApi(apiConfig());
    if (action.payload.medium === MediumEnum.Radio) {
      const response = yield call(() =>
        forecastApi.apiV1ForecastRadioPost({
          forecastInput: action.payload.input,
        })
      );
      yield put(receiveForecastAction.success(response));
    }
    if (action.payload.medium === MediumEnum.Tv) {
      const response = yield call(() =>
        forecastApi.apiV1ForecastTvPost({
          forecastInput: action.payload.input,
        })
      );
      yield put(receiveForecastAction.success(response));
    }
  } catch (err) {
    yield put(receiveForecastAction.failure(toError(err)));
  }
}

export function* receiveForecastConversionGroups(): SagaIterator {
  try {
    const planningApi = new ForecastApi(apiConfig());
    const response = yield call(() =>
      planningApi.apiV1ForecastConversiongroupsGet()
    );
    yield put(receiveForecastConversionGroupsAction.success(response));
  } catch (err) {
    yield put(receiveForecastConversionGroupsAction.failure(toError(err)));
  }
}

export function* receiveSites(): SagaIterator {
  try {
    const requestApi = new RequestApi(apiConfig());
    const response = yield call(() => requestApi.apiV1RequestDigitalSitesGet());
    yield put(receiveSitesAction.success(response));
  } catch (err) {
    yield put(receiveSitesAction.failure(toError(err)));
  }
}

export function* receivePrograms(): SagaIterator {
  try {
    const requestApi = new RequestApi(apiConfig());
    const response = yield call(() =>
      requestApi.apiV1RequestDigitalProgramsGet()
    );
    yield put(receiveProgramsAction.success(response));
  } catch (err) {
    yield put(receiveProgramsAction.failure(toError(err)));
  }
}

export function* receiveContextTargets(): SagaIterator {
  try {
    const genericApi = new GenericApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1GenericDigitalContextGet()
    );
    yield put(receiveContextTargetsAction.success(response));
  } catch (err) {
    yield put(receiveContextTargetsAction.failure(toError(err)));
  }
}

export function* receiveTargetGroups(): SagaIterator {
  try {
    const genericApi = new GenericApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1GenericDigitalTargetgroupsGet()
    );
    yield put(receiveTargetGroupsAction.success(response));
  } catch (err) {
    yield put(receiveTargetGroupsAction.failure(toError(err)));
  }
}

export function* proposalGenerate(
  action: ReturnType<typeof proposalGenerateAction.request>
): SagaIterator {
  try {
    const proposalApi = new ProposalApi(apiConfig());
    yield call(() =>
      proposalApi.apiV1ProposalPost({ proposalRequest: action.payload })
    );

    yield put(proposalGenerateAction.success());
  } catch (err) {
    yield put(proposalGenerateAction.failure(toError(err)));
  }
}

export function* proposalUploadForecast(
  action: ReturnType<typeof proposalUploadForecastAction.request>
): SagaIterator {
  try {
    const proposalApi = new ProposalApi(apiConfig());
    const response = yield call(() =>
      proposalApi.apiV1ProposalUploadForecastPost(action.payload)
    );

    yield put(proposalUploadForecastAction.success(response));
  } catch (err) {
    yield put(proposalUploadForecastAction.failure(toError(err)));
  }
}

export function* analysisUploadFile(
  action: ReturnType<typeof analysisUploadFileAction.request>
): SagaIterator {
  try {
    const analysisApi = new CampaignAnalysisApi(apiConfig());
    const response = yield call(() =>
      analysisApi.apiV1CampaignAnalysisUploadFilePost(action.payload)
    );

    yield put(analysisUploadFileAction.success(response));
  } catch (err) {
    yield put(analysisUploadFileAction.failure(toError(err)));
  }
}

export function* campaignConfigurationFetch(
  action: ReturnType<typeof campaignConfigurationFetchAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response = yield call(() =>
      campaignApi.apiV1CampaignCodeCampaignCodeGet(action.payload)
    );

    yield put(campaignConfigurationFetchAction.success(response));
  } catch (err) {
    yield put(campaignConfigurationFetchAction.failure(toError(err)));
  }
}

export function* importConfiguration(
  action: ReturnType<typeof importConfigurationAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response: CampaignImportOutput = yield call(() =>
      campaignApi.apiV1CampaignCodePost({
        campaignConfigurationInput: action.payload,
      })
    );
    yield put(importConfigurationAction.success(response));
    if (response.orderPeriod) {
      yield call(redirectRequest, {
        id: response.initialRequestId,
        medium: response.mediumEnum,
        period: response.orderPeriod,
      });
    }
  } catch (err) {
    if ((err as Response).status === 409) {
      const { detail }: ProblemDetails = yield call(() =>
        (err as Response).json()
      );
      yield put(
        importConfigurationAction.failure({
          ...toError(err),
          detail,
        })
      );
    } else {
      yield put(importConfigurationAction.failure(toError(err)));
    }
  }
}

export function* receiveCustomerAccounts(): SagaIterator {
  try {
    const customerApi = new CustomerApi(apiConfig());
    const response = yield call(() => customerApi.apiV1CustomerAccountsGet());

    yield put(receiveCustomerAccountsAction.success(response));
  } catch (err) {
    yield put(receiveCustomerAccountsAction.failure(toError(err)));
  }
}

export function* receiveCustomerOpportunities(
  action: ReturnType<typeof receiveCustomerOpportunitiesAction.request>
): SagaIterator {
  try {
    const customerApi = new CustomerApi(apiConfig());
    if (instanceOfCustomerOpportunitiesAccountIdGet(action.payload)) {
      const response = yield call(() =>
        customerApi.apiV1CustomerOpportunitiesAccountIdGet(
          action.payload as ApiV1CustomerOpportunitiesAccountIdGetRequest
        )
      );
      yield put(receiveCustomerOpportunitiesAction.success(response));
    } else {
      const response = yield call(() =>
        customerApi.apiV1CustomerOpportunitiesAdvertiserIdGet(
          action.payload as ApiV1CustomerOpportunitiesAdvertiserIdGetRequest
        )
      );
      yield put(receiveCustomerOpportunitiesAction.success(response));
    }
  } catch (err) {
    yield put(receiveCustomerOpportunitiesAction.failure(toError(err)));
  }
}

export function* receiveCustomerContacts(
  action: ReturnType<typeof receiveCustomerContactsAction.request>
): SagaIterator {
  try {
    const customerApi = new CustomerApi(apiConfig());
    if (instanceOfCustomerContactsAccountIdGet(action.payload)) {
      const response = yield call(() =>
        customerApi.apiV1CustomerContactsAccountIdGet(
          action.payload as ApiV1CustomerContactsAccountIdGetRequest
        )
      );
      yield put(receiveCustomerContactsAction.success(response));
    } else {
      const response = yield call(() =>
        customerApi.apiV1CustomerContactsAdvertiserIdGet(
          action.payload as ApiV1CustomerContactsAdvertiserIdGetRequest
        )
      );
      yield put(receiveCustomerContactsAction.success(response));
    }
  } catch (err) {
    yield put(receiveCustomerContactsAction.failure(toError(err)));
  }
}

export function* receiveCalculation(
  action: ReturnType<typeof receiveCalculationAction.request>
): SagaIterator {
  try {
    const planningApi = new RequestApi(apiConfig());
    const response = yield call(() =>
      planningApi.apiV1RequestCalculatePost(
        action.payload as ApiV1RequestCalculatePostRequest
      )
    );
    yield put(
      receiveCalculationAction.success({
        subOrderId: action.payload.subOrderId,
        initiator: action.payload.initiator,
        ...response,
      })
    );
  } catch (err) {
    yield put(
      receiveCalculationAction.failure({
        subOrderId: action.payload.subOrderId,
        initiator: action.payload.initiator,
        ...toError(err),
      })
    );
  }
}

function instanceOfCustomerOpportunitiesAccountIdGet(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is ApiV1CustomerOpportunitiesAccountIdGetRequest {
  return "accountId" in object;
}

function instanceOfCustomerContactsAccountIdGet(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is ApiV1CustomerContactsAccountIdGetRequest {
  return "accountId" in object;
}
