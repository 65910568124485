import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Form,
  Icons,
  Pageheader,
  Row,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { Affix, Alert, App as AntApp, Form as AntForm, Col, Space } from "antd";
import { Store } from "antd/lib/form/interface";
import { isDate } from "date-fns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormFinishInfo } from "rc-field-form/lib/FormContext";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  CommercialInstructionRequest,
  DateSpan,
  MediumEnum,
  OrderRequest,
  OrderStatus,
  PackageChoiceEnum,
  SubOrderRequest,
} from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  clearGroupedPackagesRadioAction,
  clearGroupedPackagesTvAction,
  clearProductsAction,
  proposalGenerateAction,
  receiveProductsAction,
  saveRequestAction,
} from "../../store/campaignCreate/actions";
import { SaveRequestSuccessProps } from "../../store/campaignCreate/models";
import {
  deleteInstructionAction,
  saveMultiCampaignInstructionsAction,
} from "../../store/campaignDetail/actions";
import { forwardCampaignAction } from "../../store/campaignForward/actions";
import { deleteCampaignAction } from "../../store/campaigns/actions";
import { StoreModel } from "../../store/models";
import { UniqueStrings } from "../../utils";
import { getFormattedDateShort } from "../../utils/dateHelper";
import { usePrevious } from "../../utils/hooks";
import { isBudgetOrBreakSelectionChoiceEnabled } from "../../utils/settingsHelper";
import { ValidationResults } from "../campaigns/models";
import SubOrdersContainer from "../campaigns/SubOrdersContainer";
import Notification from "../Notification";
import CampaignBackButton from "../partials/CampaignBackButton";
import PeriodClosedTooltip from "../partials/Cards/CampaignDetail/PeriodClosedTooltip";
import { OnlineInstructionsPerSubOrder } from "../partials/OnlineInstructions/types";
import Step from "../partials/Steps/Step";
import Steps, { StepStatus } from "../partials/Steps/Steps";
import HistoryTooltip from "../partials/Tooltips/HistoryTooltip/HistoryTooltip";
import OrderStatusTooltip from "../partials/Tooltips/OrderstatusTooltip/OrderStatusTooltip";
import styles from "./CampaignCreate.module.less";
import Details from "./details/Details";
import FinalizeModal from "./FinalizeModal";
import CampaignForwardButton from "./forward/CampaignForwardButton";
import importMessages from "./importMessages";
import InstructionsForConcept, {
  getMinMax,
} from "./instructions/InstructionsForConcept";
import OnlineInstructionsForConcept from "./instructions/OnlineInstructionsForConcept";
import MoreMenu from "./MoreMenu";
import CampaignProposalButton from "./proposal/CampaignProposalButton";
import {
  campaignCreateRootSelector,
  campaignCreateStateSelector,
} from "./selectors";

interface CampaignCreateProps {
  onAddForecastConversionGroup: (value: string) => void;
  onDeleteForecastConversionGroup: (value: string) => void;
  onDeleteSubOrder: (id: number) => void;
  onAddSubOrder: (subOrder: Partial<SubOrderRequest>) => void;
  onChangeSubOrder: (subOrder: Partial<SubOrderRequest>) => void;
  onFormFinish: (
    name: string,
    { forms }: FormFinishInfo,
    handleFormValid: (
      formName: string,
      validationResults: ValidationResults[],
      values: Store
    ) => void
  ) => void;
  orderRequest: OrderRequest | undefined;
  updateOrderRequest: (orderRequest: OrderRequest | undefined) => void;
  onCancel: () => void;
}

const emptyStringArray: string[] = [];

const CampaignCreate = memo(
  ({
    onAddForecastConversionGroup,
    onAddSubOrder,
    onChangeSubOrder,
    onDeleteForecastConversionGroup,
    onDeleteSubOrder,
    onFormFinish,
    orderRequest,
    updateOrderRequest,
    onCancel,
  }: CampaignCreateProps) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { message, modal } = AntApp.useApp();
    const detailsRef = useRef<HTMLInputElement>(null);
    const subOrdersRef = useRef<HTMLInputElement>(null);
    const uziRef = useRef<HTMLInputElement>(null);
    const {
      medium: mediumFromParams,
      initialRequestId: initialRequestIdFromParams,
      from: fromFromParams,
      to: toFromParams,
    } = useParams<{
      medium?: string;
      initialRequestId?: string;
      from?: string;
      to?: string;
    }>();

    const medium = mediumFromParams as MediumEnum;
    const initialRequestId = Number(initialRequestIdFromParams ?? 0);
    const from = useMemo(
      () => (fromFromParams ? new Date(fromFromParams) : undefined),
      [fromFromParams]
    );
    const to = useMemo(
      () => (toFromParams ? new Date(toFromParams) : undefined),
      [toFromParams]
    );

    const [showFinalizeModal, setShowFinalizeModal] = useState(false);
    const [validationErrors, setValidationErrors] =
      useState<string[]>(emptyStringArray);
    const [isFinalizing, setIsFinalizing] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const { initialRequestState, selectedSalesPeriod } = useSelector(
      (state: StoreModel) => campaignCreateStateSelector(state, orderRequest)
    );

    const {
      salesPeriods,
      loading,
      productsByAdvertiser,
      initialRequestState: initialRequestStoreState,
      bookingDate: { date: minimalSubOrderDate },
      account,
      settings,
    } = useSelector((state: StoreModel) => {
      const date =
        from && isDate(from) ? from : orderRequest?.period?.from ?? new Date();
      return campaignCreateRootSelector(state, date, initialRequestId);
    });

    const prevInitialRequestStoreState = usePrevious(initialRequestStoreState);
    const prevInitialRequestId = usePrevious(initialRequestId);

    // Bij een bestaand concept tonen we de eerste stap als voltooid
    const [stepStatusDetails, setStepStatusDetails] = useState<StepStatus>(
      initialRequestId ? "finished" : "process"
    );

    const [stepStatusSubOrders, setStepStatusSubOrders] =
      useState<StepStatus>("wait");

    // TODO: Momenteel alleen tonen zodra er deelorders zijn
    const [stepStatusUzi, setStepStatusUzi] = useState<StepStatus>(
      initialRequestId ? "finished" : "wait"
    );

    const getProducts = useCallback(
      (mediumValue: MediumEnum, period: DateSpan) => {
        dispatch(
          receiveProductsAction.request({
            medium: mediumValue,
            from: new Date(period.from),
            to: new Date(period.to),
          })
        );
      },
      [dispatch]
    );

    useEffect(() => {
      if (!initialRequestState?.canEdit) {
        setStepStatusDetails("finished");
        setStepStatusSubOrders("finished");
        setStepStatusUzi("finished");
      }
      if (initialRequestId) {
        setStepStatusUzi("finished");
      }
    }, [initialRequestId, initialRequestState]);

    useEffect(() => {
      // Bij een bestaand concept met deelorders tonen we de tweede stap als voltooid
      if (initialRequestId && orderRequest && stepStatusSubOrders === "wait") {
        setStepStatusSubOrders(
          (orderRequest?.subOrders?.length ?? 0) > 0 ? "finished" : "process"
        );
      }
    }, [
      initialRequestId,
      orderRequest,
      orderRequest?.subOrders?.length,
      stepStatusSubOrders,
    ]);

    useEffect(() => {
      if (initialRequestId && medium && from && to) {
        getProducts(medium, { from, to });
      }
    }, [medium, from, to, getProducts, initialRequestId]);

    // Wijzigen van de details van de hooforder
    const handleDetailUpdate = useCallback(
      (values: Store) => {
        if (values.medium && values.medium !== orderRequest?.medium) {
          // Verwijder bestaande producten uit de store als het medium wijzigt
          dispatch(clearProductsAction());
        }
        if (
          values.medium &&
          values.period &&
          (values.medium !== orderRequest?.medium ||
            values.period?.from !== orderRequest?.period?.from)
        ) {
          // Haal de producten op als het medium of periode gewijzigd worden
          getProducts(values.medium, values.period);

          // Reset grouped packages
          dispatch(clearGroupedPackagesRadioAction());
          dispatch(clearGroupedPackagesTvAction());
        }

        updateOrderRequest({ ...orderRequest, ...(values as OrderRequest) });
      },
      [orderRequest, updateOrderRequest, dispatch, getProducts]
    );

    const handleDetailFinish = useCallback(() => {
      if (stepStatusDetails === "process") {
        setStepStatusDetails("finished");
        setStepStatusSubOrders("process");
        setStepStatusUzi(
          (orderRequest?.subOrders?.length ?? 0) > 0 ? "finished" : "wait"
        );
        subOrdersRef.current?.scrollIntoView(
          (orderRequest?.subOrders?.length ?? 0) > 0
            ? { block: "end" }
            : { block: "center" }
        );
      }
    }, [orderRequest, stepStatusDetails]);

    const handleSubOrdersFinish = useCallback((next: boolean) => {
      setStepStatusSubOrders("finished");
      if (next) {
        setStepStatusUzi("finished");
        uziRef.current?.scrollIntoView({ block: "start" });
      } else {
        setStepStatusDetails("process");
        detailsRef.current?.scrollIntoView({ block: "end" });
      }
    }, []);

    const handleDetailEdit = useCallback(() => {
      setStepStatusDetails("process");
      setStepStatusSubOrders(
        (orderRequest?.subOrders?.length ?? 0) > 0 ? "finished" : "wait"
      );
      setStepStatusUzi(
        (orderRequest?.subOrders?.length ?? 0) > 0 ? "finished" : "wait"
      );
    }, [orderRequest]);

    const handleSubOrdersEdit = useCallback(() => {
      setStepStatusDetails("finished");
      setStepStatusSubOrders("process");
      setStepStatusUzi(
        (orderRequest?.subOrders?.length ?? 0) > 0 ? "finished" : "wait"
      );
    }, [orderRequest]);

    const handleInstructionsEdit = useCallback(() => {
      // TODO
      setStepStatusDetails("finished");
      setStepStatusSubOrders("finished");
      setStepStatusUzi("process");
    }, []);

    const setConceptInstructions = useCallback(
      (instructions: CommercialInstructionRequest[]) => {
        updateOrderRequest({
          ...(orderRequest as OrderRequest),
          instructions,
        });
      },
      [orderRequest, updateOrderRequest]
    );

    const upsertOnlineInstruction = useCallback(
      (newInstructions: OnlineInstructionsPerSubOrder[]) => {
        newInstructions.forEach((s) => {
          onChangeSubOrder({
            ...orderRequest?.subOrders?.find((o) => o.id === s.subOrderId),
            onlineInstructionsPerPeriod: s.instructionsPerPeriod,
          });
        });
      },
      [onChangeSubOrder, orderRequest?.subOrders]
    );

    const upsertInstruction = useCallback(
      (instruction: CommercialInstructionRequest, onOkCallback: () => void) => {
        const conceptInstructions = orderRequest?.instructions ?? [];
        const idx = conceptInstructions.findIndex(
          (ci) => ci.id === instruction.id
        );

        const newSet =
          idx === -1
            ? [...conceptInstructions, instruction]
            : [
                ...conceptInstructions.slice(0, idx),
                instruction,
                ...conceptInstructions.slice(idx + 1),
              ];

        setConceptInstructions(newSet);
        onOkCallback();
      },
      [orderRequest?.instructions, setConceptInstructions]
    );

    const deleteOnlineInstruction = useCallback(
      (subOrderId: number) => {
        onChangeSubOrder({
          ...orderRequest?.subOrders?.find((o) => o.id === subOrderId),
          onlineInstructionsPerPeriod: undefined,
        });
      },
      [onChangeSubOrder, orderRequest?.subOrders]
    );

    const deleteInstruction = useCallback(
      (instructionId: number) => {
        const conceptInstructions = orderRequest?.instructions ?? [];
        if (instructionId > 0) {
          // delete saved instructions for radio/tv
          dispatch(deleteInstructionAction.request({ medium, instructionId }));
        }

        // remove from concepts
        const newSet = conceptInstructions.filter(
          ({ id }) => id !== instructionId
        );
        setConceptInstructions(newSet);
      },
      [dispatch, medium, orderRequest?.instructions, setConceptInstructions]
    );

    const handleSaveInstructions = useCallback(
      (
        order: OrderRequest,
        currentSuccessMessage?: string,
        onSuccess?: (props: SaveRequestSuccessProps) => void
      ) => {
        setSuccessMessage(currentSuccessMessage);
        const { min: periodFrom, max: periodTo } = getMinMax(order);
        dispatch(
          saveMultiCampaignInstructionsAction.request({
            instructions: orderRequest?.instructions ?? [],
            successProps: {
              medium: order.medium,
              orderId: order.id,
              productId: order.productId,
              periodFrom,
              periodTo,
            },
            onSuccess,
          })
        );
      },
      [dispatch, orderRequest?.instructions]
    );

    useEffect(() => {
      if (
        successMessage &&
        ((prevInitialRequestStoreState === ReduxStoreState.Loading &&
          initialRequestStoreState === ReduxStoreState.Success) ||
          initialRequestId !== prevInitialRequestId)
      ) {
        message.success(successMessage);
        setSuccessMessage(undefined);
        setIsFinalizing(false);
      } else if (
        prevInitialRequestStoreState === ReduxStoreState.Loading &&
        initialRequestStoreState === ReduxStoreState.Failure
      ) {
        if (successMessage) {
          message.error(
            i18n._(t`Er ging iets mis bij het opslaan van je campagne`),
            10
          );
        } else if (isFinalizing) {
          message.error(
            i18n._(t`Er ging iets mis bij het indienen van je campagne`),
            10
          );
        } else {
          message.error(
            i18n._(t`Er ging iets mis bij het ophalen van je campagne`),
            10
          );
        }
        setSuccessMessage(undefined);
        setIsFinalizing(false);
      }
    }, [
      i18n,
      initialRequestId,
      initialRequestStoreState,
      isFinalizing,
      message,
      prevInitialRequestId,
      prevInitialRequestStoreState,
      successMessage,
    ]);

    const handleSave = useCallback(
      (
        currentSuccessMessage?: string,
        currentFormValues?: Store,
        onSuccess?: (props: SaveRequestSuccessProps, values?: Store) => void
      ) => {
        if (orderRequest) {
          setSuccessMessage(currentSuccessMessage);
          const { min: periodFrom, max: periodTo } = getMinMax(orderRequest);
          dispatch(
            saveRequestAction.request({
              orderRequest,
              successProps: {
                medium: orderRequest.medium,
                productId: orderRequest.productId,
                periodFrom,
                periodTo,
              },
              onSuccess: (props) => {
                if (
                  orderRequest.medium !== MediumEnum.Inter &&
                  (orderRequest.instructions?.length ?? 0) > 0
                ) {
                  // Opslaan van concepten voor radio/tv via losse call
                  handleSaveInstructions(
                    orderRequest,
                    successMessage,
                    onSuccess
                  );
                } else if (onSuccess) {
                  onSuccess(props, currentFormValues);
                }
              },
            })
          );
        }
      },
      [dispatch, handleSaveInstructions, orderRequest, successMessage]
    );

    const proposalGenerate = useCallback(
      ({
        medium: mediumValue,
        orderId,
        periodFrom,
        periodTo,
      }: SaveRequestSuccessProps) => {
        if (orderId) {
          dispatch(
            proposalGenerateAction.request({
              initialRequests: [
                {
                  from: new Date(periodFrom),
                  to: new Date(periodTo),
                  initialRequestId: orderId,
                  medium: mediumValue,
                },
              ],
            })
          );
        }
      },
      [dispatch]
    );

    /**
     * Methode om het "Concept campagne doorsturen" formulier te versturen.
     */
    const handleForwardSubmit = useCallback(
      (props: SaveRequestSuccessProps, values?: Store) => {
        if (!values || !props.orderId) {
          return;
        }

        dispatch(
          forwardCampaignAction.request({
            medium: props.medium,
            from: props.periodFrom,
            to: props.periodTo,
            initialRequestId: props.orderId,
            campaignForwardInput: {
              organisationCode: values.organisationCode,
              advertiserId: values.advertiserId,
              productId: values.productId,
              email: values.email,
            },
          })
        );
      },
      [dispatch]
    );

    /**
     * Voordat een aanvraag ingediend kan worden, moet 'ie nogmaals gevalideerd
     * worden, naast de normale formulier-validatie.
     */
    const validateOrderBeforeFinalize = useCallback(
      (or: OrderRequest): string[] => {
        if (!or.subOrders) {
          return [i18n._(t`Deelorders zijn verplicht`)];
        }

        let errors = emptyStringArray;
        or.subOrders.forEach((subOrder) => {
          const hasToHaveBlocks =
            subOrder.packageChoice === PackageChoiceEnum.FixedCosts &&
            !isBudgetOrBreakSelectionChoiceEnabled(settings.settings);
          if (
            hasToHaveBlocks &&
            or.medium === MediumEnum.Tv &&
            (subOrder.breakSelection?.length ?? 0) === 0
          ) {
            // Bij TV zijn blokken verplicht! (specifieke inkoop)
            const subOrderTitle = `${
              subOrder._package.name
            } ${subOrder.spotLength?.map((s) => s && `${s}"`).join(" + ")} ${
              subOrder.targetGroup?.targetGroupId
            }`;

            errors = [
              ...errors,
              i18n._(
                t`Er zijn geen blokken geselecteerd bij: ${subOrderTitle}`
              ),
            ];
          }
        });

        return errors;
      },
      [i18n, settings]
    );

    const handleFinalize = useCallback(() => {
      if (orderRequest) {
        setValidationErrors(validateOrderBeforeFinalize(orderRequest));
        setShowFinalizeModal(true);
      }
    }, [orderRequest, validateOrderBeforeFinalize]);

    const handleCloseFinalizeModal = useCallback(() => {
      setValidationErrors(emptyStringArray);
      setShowFinalizeModal(false);
    }, []);

    const handleFormValid = useCallback(
      (name: string, results: ValidationResults[], values: Store) => {
        const errors = results.filter((r) => !r.valid);
        if (errors.length > 0) {
          // Afhandeling als er een incorrect veld in 1 van de formulier is
          message.error(i18n._(t`Niet alle velden zijn correct ingevuld`));
          if (
            orderRequest?.subOrders?.some((s) =>
              errors.some((e) => e.name.endsWith(`-${s.id}`))
            )
          ) {
            setStepStatusSubOrders("process");
          }
        } else if (name === "save") {
          // Opslaan van het concept
          handleSave(i18n._(t`De aanvraag is opgeslagen`), values);
        } else if (name === "finalize") {
          // indienen van het concept
          const realSubmit = () => {
            setIsFinalizing(true);
            handleFinalize();
            detailsRef.current?.scrollIntoView({ block: "end" });
          };

          // Check for YourTime and blockselection => if true, show modal with warning
          const yourTimeSubOrders =
            orderRequest?.subOrders?.filter((s) => s._package.isYourTime) ?? [];
          const showWarning =
            yourTimeSubOrders.length > 0 &&
            yourTimeSubOrders.every(
              (s) => !s.breakSelection || s.breakSelection.length === 0
            );

          if (showWarning) {
            modal.confirm({
              title: i18n._(t`Blokselectie incompleet`),
              content: (
                <Typography.Paragraph>
                  {i18n._(
                    t`Weet je het zeker dat je deze aanvraag wilt indienen zonder YourTime blokselectie?`
                  )}
                </Typography.Paragraph>
              ),
              onOk: realSubmit,
              cancelText: i18n._(t`Annuleren`),
              okText: i18n._(t`Indienen`),
            });
          } else {
            realSubmit();
          }
        } else if (name === "saveSubOrdersNext") {
          handleSubOrdersFinish(true);
        } else if (name === "saveSubOrdersPrevious") {
          handleSubOrdersFinish(false);
        } else if (name === "proposal") {
          handleSave(undefined, values, proposalGenerate);
        } else if (name === "forward") {
          handleSave(undefined, values, handleForwardSubmit);
        }
      },
      [
        handleFinalize,
        handleForwardSubmit,
        handleSave,
        handleSubOrdersFinish,
        i18n,
        message,
        modal,
        orderRequest?.subOrders,
        proposalGenerate,
      ]
    );

    const handleFormFinish = useCallback(
      (name: string, finishInfo: FormFinishInfo) => {
        // Alleen afhandeling als er op een submit knop in een save-formulier gedrukt wordt
        onFormFinish(name, finishInfo, handleFormValid);
      },
      [handleFormValid, onFormFinish]
    );

    // Verwijderen van een concept
    const handleDelete = useCallback(() => {
      if (orderRequest?.id) {
        modal.confirm({
          title: i18n._(t`Weet je het zeker?`),
          content: (
            <Typography.Paragraph>
              {i18n._(
                t`Weet je zeker dat je dit concept wilt verwijderen? Deze actie is niet terug te draaien.`
              )}
            </Typography.Paragraph>
          ),
          onOk: () => {
            dispatch(
              deleteCampaignAction.request({
                initialRequestId: orderRequest.id ?? 0,
                medium,
                dateFrom: orderRequest.period.from,
                dateUntil: orderRequest.period.to,
              })
            );
            navigate("/campaigns");
          },
          cancelText: i18n._(t`Behouden`),
          okText: i18n._(t`Verwijderen`),
        });
      }
    }, [
      dispatch,
      i18n,
      medium,
      modal,
      navigate,
      orderRequest?.id,
      orderRequest?.period?.from,
      orderRequest?.period?.to,
    ]);

    const importConfiguration = useSelector(
      (store: StoreModel) => store.importConfiguration
    );

    const showImportMessages = useMemo(
      () =>
        importConfiguration.state === ReduxStoreState.Success &&
        (importConfiguration.messages?.length ?? 0) > 0,
      [importConfiguration.messages?.length, importConfiguration.state]
    );

    const prospectAdvertisers = useMemo(
      () =>
        productsByAdvertiser
          .filter(({ isProspect }) => isProspect)
          .map((a) => a.id),
      [productsByAdvertiser]
    );

    const isProspect = useMemo(
      () => prospectAdvertisers.includes(orderRequest?.advertiserId ?? 0),
      [orderRequest?.advertiserId, prospectAdvertisers]
    );

    return (
      <>
        <Helmet>
          <title>
            {orderRequest?.campaignName && orderRequest?.campaignName !== ""
              ? orderRequest.campaignName
              : i18n._(t`Nieuwe Campagne`)}
          </title>
        </Helmet>

        <AntForm.Provider onFormFinish={handleFormFinish}>
          <Pageheader
            title={
              orderRequest?.campaignName && orderRequest?.campaignName !== ""
                ? orderRequest.campaignName
                : i18n._(t`Nieuwe Campagne`)
            }
            icon={
              <Icons.CampaignIcon
                width="100%"
                height="100%"
                fill="rgba(129, 176, 210, 0.2)"
              />
            }
          >
            <Space wrap>
              <MoreMenu
                handleCancel={onCancel}
                handleDelete={handleDelete}
                initialRequestState={initialRequestState}
                loading={loading}
              />

              <CampaignProposalButton
                medium={orderRequest?.medium}
                dateFrom={orderRequest?.period?.from}
                dateTo={orderRequest?.period?.to}
                visible={Boolean(settings.settings?.enableGenerateProposal)}
                disabled={
                  !initialRequestState?.canEdit ||
                  loading ||
                  (orderRequest?.subOrders?.length ?? 0) === 0
                }
                account={account}
                saving={loading}
              />

              <CampaignForwardButton
                medium={orderRequest?.medium}
                dateFrom={orderRequest?.period?.from}
                dateTo={orderRequest?.period?.to}
                visible={
                  Boolean(settings.settings?.enableForward) &&
                  Boolean(initialRequestState?.isConcept) &&
                  (account.sterInlog.internalUser ||
                    account.roles.includes("Admin"))
                }
                disabled={
                  !initialRequestState?.canEdit ||
                  loading ||
                  (orderRequest?.subOrders?.length ?? 0) === 0
                }
                saving={loading}
              />

              {initialRequestState?.canEdit && (
                <Form name="save" className={styles.save}>
                  <Form.Item>
                    <Button
                      mode="secondary"
                      htmlType="submit"
                      disabled={loading}
                    >
                      <SaveOutlined color="#fff" width="24px" />
                      <Trans>Opslaan</Trans>
                    </Button>
                  </Form.Item>
                </Form>
              )}

              <Form name="finalize" className={styles.save}>
                <Form.Item>
                  <Button
                    mode="primary"
                    htmlType="submit"
                    disabled={
                      !initialRequestState?.canFinalize ||
                      loading ||
                      (orderRequest?.subOrders?.length ?? 0) === 0 ||
                      isProspect
                    }
                  >
                    <SendOutlined color="#fff" />
                    <Trans>Indienen</Trans>
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Pageheader>
          <Spinner spinning={loading}>
            <ContentContainer className="content">
              {isProspect && (
                <Affix className="tooltipAffix" offsetTop={102}>
                  <div>
                    <div className="ant-tooltip-arrow">
                      <span className="ant-tooltip-arrow-content" />
                    </div>
                    <Alert
                      showIcon
                      type="info"
                      message=""
                      className="submitAlert"
                      description={
                        <Trans>
                          Een campagne voor een nieuwe adverteerder kan niet
                          ingediend worden.
                        </Trans>
                      }
                    />
                  </div>
                </Affix>
              )}
              {initialRequestState?.subOrdersBeforeFrozenPeriod && (
                <Affix className="tooltipAffix" offsetTop={102}>
                  <div>
                    <div className="ant-tooltip-arrow">
                      <span className="ant-tooltip-arrow-content" />
                    </div>
                    <Alert
                      showIcon
                      type="info"
                      message=""
                      className="submitAlert"
                      description={
                        <Trans>
                          Je aanvraag is niet in te dienen, pas je deelorders
                          aan zodat deze op{" "}
                          {getFormattedDateShort(minimalSubOrderDate)} of later
                          starten.
                        </Trans>
                      }
                    />
                  </div>
                </Affix>
              )}
              {!initialRequestState?.periodOpenForRequest &&
                selectedSalesPeriod && (
                  <PeriodClosedTooltip
                    selectedSalesPeriod={selectedSalesPeriod}
                  />
                )}
              <Notification />
              <div className={styles.topContainer}>
                <div className="back-link">
                  <CampaignBackButton />
                </div>
                <div className={styles.right}>
                  {orderRequest?.auditLogs && (
                    <HistoryTooltip logs={orderRequest?.auditLogs} />
                  )}
                  <OrderStatusTooltip
                    status={orderRequest?.requestStatus ?? OrderStatus.Concept}
                  />
                </div>
              </div>

              <Container>
                {orderRequest?.spreadedFirstPart && ( // melding wanneer dit het eerste deel is van een maandoverschrijdende order
                  <Row className="center">
                    <Col span={20}>
                      <Alert
                        showIcon
                        className={styles.spreadAlert}
                        type="warning"
                        message=""
                        description={
                          <Trans>
                            Let op: deze order is onderdeel van een
                            maandoverschrijdende aanvraag en is opgesplitst in
                            twee orders: voor elke maand één. Hieronder vind je
                            de deelorder(s) die in de eerste maand vallen. Het
                            budget is hierbij verdeeld over de twee maanden.
                            <br /> De order voor de volgende maand heeft de
                            status Concept en zal automatisch ingediend worden
                            wanneer deze periode vrijgegeven wordt.
                          </Trans>
                        }
                      />
                    </Col>
                  </Row>
                )}
                {orderRequest?.spreadedSecondPart && ( // melding wanneer dit het tweede deel is van een maandoverschrijdende order
                  <Row className="center">
                    <Col span={20}>
                      <Alert
                        showIcon
                        className={styles.spreadAlert}
                        type="warning"
                        message=""
                        description={
                          <Trans>
                            Let op: deze order is onderdeel van een
                            maandoverschrijdende order en zal automatisch
                            ingediend worden wanneer deze periode vrijgegeven
                            wordt.
                          </Trans>
                        }
                      />
                    </Col>
                  </Row>
                )}
                <Steps>
                  <Step
                    status={stepStatusDetails}
                    title={i18n._(t`gegevens`)}
                    onEditClick={handleDetailEdit}
                    allowEdit={
                      (orderRequest?.subOrders?.length ?? 0) === 0 ||
                      stepStatusSubOrders !== "process"
                    }
                    showEdit={initialRequestState?.canEdit}
                  >
                    <Row>
                      <Col lg={20} md={24}>
                        <div ref={detailsRef}>
                          {(initialRequestId ? orderRequest : true) &&
                            settings.settings && ( // Als er een id is moet de aanvraag ook bestaan. Als er geen id is is het een nieuwe aanvraag
                              <Details
                                onFinish={handleDetailFinish}
                                onUpdate={handleDetailUpdate}
                                order={orderRequest}
                                editable={stepStatusDetails === "process"}
                                salesPeriods={salesPeriods}
                                loading={loading}
                                productsByAdvertiser={productsByAdvertiser}
                                settings={settings.settings}
                              />
                            )}
                        </div>
                      </Col>
                    </Row>
                  </Step>
                  <Step
                    status={stepStatusSubOrders}
                    title={i18n._(t`pakketten`)}
                    onEditClick={handleSubOrdersEdit}
                    allowEdit={stepStatusDetails !== "process"}
                    showEdit={initialRequestState?.canEdit}
                  >
                    <div ref={subOrdersRef} id="suborders">
                      {orderRequest?.subOrders &&
                        selectedSalesPeriod &&
                        showImportMessages && (
                          <Row className="center">
                            <Col span={20}>
                              <Alert
                                showIcon
                                className={styles.spreadAlert}
                                type="warning"
                                message=""
                                description={UniqueStrings(
                                  importConfiguration.messages?.map(
                                    (s) => s.message
                                  ) ?? []
                                ).map((msg) => i18n._(importMessages[msg]))}
                              />
                            </Col>
                          </Row>
                        )}
                      {orderRequest && selectedSalesPeriod && (
                        <SubOrdersContainer
                          onAddForecastConversionGroup={
                            onAddForecastConversionGroup
                          }
                          onDeleteForecastConversionGroup={
                            onDeleteForecastConversionGroup
                          }
                          onDeleteSubOrder={onDeleteSubOrder}
                          onSubOrderChange={onChangeSubOrder}
                          onAddNewSubOrder={onAddSubOrder}
                          orderRequest={orderRequest}
                          selectedSalesPeriod={selectedSalesPeriod}
                          stepStatusSubOrders={stepStatusSubOrders}
                        />
                      )}
                    </div>
                  </Step>
                  {Boolean(settings.settings?.enableInstructionsFromRequest) &&
                    orderRequest &&
                    orderRequest.medium &&
                    orderRequest.medium !== MediumEnum.Inter && (
                      <Step
                        status={stepStatusUzi}
                        title={i18n._(t`uitzendinstructies`)}
                        onEditClick={handleInstructionsEdit}
                        allowEdit={false}
                        showEdit={false}
                      >
                        <div ref={uziRef}>
                          {orderRequest.productId &&
                            orderRequest.period.from &&
                            orderRequest.period.to && (
                              <InstructionsForConcept
                                orderRequest={orderRequest}
                                concepts={orderRequest.instructions ?? []}
                                onSave={upsertInstruction}
                                onDelete={deleteInstruction}
                              />
                            )}
                        </div>
                      </Step>
                    )}
                  {Boolean(settings.settings?.enableInstructionsFromRequest) &&
                    orderRequest &&
                    orderRequest.medium &&
                    orderRequest.medium === MediumEnum.Inter && (
                      <Step
                        status={stepStatusUzi}
                        title={i18n._(t`uitzendinstructies`)}
                        onEditClick={handleInstructionsEdit}
                        allowEdit={false}
                        showEdit={false}
                      >
                        <div ref={uziRef}>
                          {orderRequest.productId &&
                            orderRequest.period.from &&
                            orderRequest.period.to && (
                              <OnlineInstructionsForConcept
                                orderRequest={orderRequest}
                                onSave={upsertOnlineInstruction}
                                onDelete={deleteOnlineInstruction}
                              />
                            )}
                        </div>
                      </Step>
                    )}
                </Steps>
                {showFinalizeModal && orderRequest && (
                  <FinalizeModal
                    order={orderRequest}
                    saveInstructions={
                      orderRequest.medium !== MediumEnum.Inter &&
                      (orderRequest.instructions?.length ?? 0) > 0
                        ? handleSaveInstructions
                        : undefined
                    }
                    onClose={handleCloseFinalizeModal}
                    validationErrors={validationErrors}
                  />
                )}
              </Container>
            </ContentContainer>
          </Spinner>
        </AntForm.Provider>
      </>
    );
  }
);

export default CampaignCreate;
