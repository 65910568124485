/* eslint-disable no-nested-ternary */
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, MultiOption, Radio, Select } from "@ster/ster-toolkit";
import { Radio as AntRadio } from "antd";
import { memo } from "react";

import { DigitalProgram, ValueList } from "../../../../api";
import { getPopupContainerSubOrders } from "../../../../utils";
import selectStyles from "../OnlineSubOrder.module.less";

const ProgramSiteOrContextTargeting = memo(
  ({
    programs,
    contextTargets,
    isDisplay,
    handleSelectAllSites,
    sites,
  }: {
    programs: DigitalProgram[];
    contextTargets: ValueList[];
    isDisplay: boolean;
    handleSelectAllSites: (selectAll: boolean) => void;
    sites: ValueList[];
  }) => {
    const { i18n } = useLingui();

    return (
      <>
        <Form.Item
          label={i18n._(t`Targeting`)}
          name="targeting"
          rules={[
            {
              required: true,
              message: i18n._(t`Kies een targeting optie`),
            },
          ]}
        >
          <Radio.Group>
            {isDisplay ? (
              <AntRadio value="site">
                <Trans>Site</Trans>
              </AntRadio>
            ) : (
              <AntRadio value="program">
                <Trans>Programma</Trans>
              </AntRadio>
            )}
            <AntRadio value="context">
              <Trans>Context</Trans>
            </AntRadio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.targeting !== currentValues.targeting
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("targeting") === "program" ? (
              <Form.Item
                label={i18n._(t`Programma's`)}
                name="program"
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Kies 1 of meer programma's`),
                  },
                ]}
              >
                <Select.Multi
                  placeholder={i18n._(t`Kies 1 of meer programma's`)}
                  showSearch
                  singleLine={false}
                  className={selectStyles["selection-max-width"]}
                  getPopupContainer={getPopupContainerSubOrders}
                >
                  {programs.map((s) => (
                    <MultiOption key={s.name} value={s.name} title={s.name} />
                  ))}
                </Select.Multi>
              </Form.Item>
            ) : getFieldValue("targeting") === "site" ? (
              <Form.Item
                label={i18n._(t`Sites`)}
                name="site"
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Kies 1 of meer sites`),
                  },
                ]}
              >
                <Select.Multi
                  placeholder={i18n._(t`Kies 1 of meer sites`)}
                  showSearch
                  singleLine={false}
                  className={selectStyles["selection-max-width"]}
                  getPopupContainer={getPopupContainerSubOrders}
                  onSelectAll={handleSelectAllSites}
                  selectAllLabel={i18n._(t`Alles selecteren`)}
                  deselectAllLabel={i18n._(t`Niets selecteren`)}
                >
                  {sites.map((s) => (
                    <MultiOption
                      key={s.name}
                      value={s.name ?? ""}
                      title={s.name}
                    />
                  ))}
                </Select.Multi>
              </Form.Item>
            ) : getFieldValue("targeting") === "context" ? (
              <Form.Item
                label={i18n._(t`Context targeting`)}
                name="contextTargets"
                rules={[
                  {
                    validator: (_, value): Promise<void> =>
                      value && value.length >= 2
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              i18n._(
                                t`Je dient minimaal 2 items te selecteren.`
                              )
                            )
                          ),
                  },
                ]}
              >
                <Select.Multi
                  placeholder={i18n._(t`Kies 2 of meer contexten`)}
                  showSearch
                  singleLine={false}
                  className={selectStyles["selection-max-width"]}
                >
                  {contextTargets.map((s) => (
                    <MultiOption
                      key={s.aka}
                      value={s.aka ?? ""}
                      title={s.name}
                    />
                  ))}
                </Select.Multi>
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </>
    );
  }
);

export default ProgramSiteOrContextTargeting;
